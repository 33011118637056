import Vue from "vue";
import VueRouter from "vue-router";
// 不再直接导入MediaReports和VideoCenter
// import MediaReports from '../components/MediaReports.vue'
// import VideoCenter from '../components/VideoCenter.vue'

Vue.use(VueRouter);

const routes = [
  { path: "/",
    name: "Home",
    component: () => import('../views/HomeView.vue'),
    meta: { title: "首页" }
  },

  {
    path: "/About",
    name: "About",
    component: () => import('../views/About/AboutView.vue'),
    meta: { title: "了解海邻" }
  },
  { path: "/Corporateculture",
    name: "Corporateculture",
    component: () => import('../views/About/Corporateculture.vue'),
    meta: { title: "企业文化" }
  },
  { path: "/Corporatehonors",
    name: "Corporatehonors",
    component: () => import('../views/About/Corporatehonors.vue'),
    meta: { title: "企业荣誉" }
  },
  { path: "/History",
    name: "History",
    component: () => import('../views/About/History.vue'),
    meta: { title: "发展历程" } },

  { path: "/Contact",
    name: "Contact",
    component: () => import('../views/Contact/Contactus.vue'),
    meta: { title: "联系我们" }
  },
  { path: "/Dynamic",
    name: "Dynamic",
    component: () => import('../views/Dynamic/Dynamicdata.vue'),
    meta: { title: "海邻动态" }
  },

  { path: "/Dynamic",
    name: "Dynamic",
    component: () => import('../views/Dynamic/Dynamicdata.vue'),
    redirect: '/Dynamic/company',
    meta: { title: "海邻动态" }
  },
  {
    path: '/Dynamic/company',
    name: 'CompanyDynamic',
    component: () => import('../views/Dynamic/Dynamicdata.vue'),
    meta: { title: "公司动态" }
  },
  {
    path: '/Dynamic/media',
    name: 'MediaReports',
    component: () => import('../views/Dynamic/Dynamicdata.vue'),
    meta: { title: "媒体报道" }
  },
  {
    path: '/Dynamic/video',
    name: 'VideoCenter',
    component: () => import('../views/Dynamic/Dynamicdata.vue'),
    meta: { title: "视频中心" }
  },
  { path: "/Product",
    name: "Product",
    component: () => import('../views/Product/ProductLayout.vue'),
    redirect: '/Product/ProductCandidaYeast/pig',
    meta: { title: "产品中心" },
    children: [
      {
        path: 'ProductCandidaYeast/:type',
        name: 'candida-type',
        component: () => import('../views/Product/ProductCandidaYeast.vue'),
        props: true,
        meta: { title: "产朊假丝酵母蛋白" }
      },
      {
        path: 'ProductGrainWineYeast/:type',
        name: 'grain-type',
        component: () => import('../views/Product/ProductGrainWineYeast.vue'),
        props: true,
        meta: { title: "酿酒酵母培养物" }
      },
      {
        path: 'ProductCornDryAlcohol/:type',
        name: 'corn-type',
        component: () => import('../views/Product/ProductCornDryAlcohol.vue'),
        props: true,
        meta: { title: "玉米干酒精" }
      },
      {
        path: 'other',
        name: 'other-products',
        component: () => import('../views/Product/other.vue'),
        meta: { title: "其他" }
      }
    ]
  },
  { path: "/Research",
    name: "Research",
    component: () => import('../views/Research/ResearchCenter.vue'),
    meta: { title: "科研中心" }
  },
  { path: "/Research/team",
    name: "ResearchTeam",
    component: () => import('../views/Research/ResearchCenter.vue'),
    meta: { title: "科研团队" }
  },
  { path: "/Research/power",
    name: "ResearchStrength",
    component: () => import('../views/Research/ResearchCenter.vue'),
    meta: { title: "科研力量" }
  },
  { path: "/Research/content",
    name: "ResearchContent",
    component: () => import('../views/Research/ResearchCenter.vue'),
    meta: { title: "研究内容" }
  },
  { path: "/Research/achievements",
    name: "ResearchAchievements",
    component: () => import('../views/Research/ResearchCenter.vue'),
    meta: { title: "科研成果" }
  },
  { path: "/Research/quality",
    name: "QualityManagement",
    component: () => import('../views/Research/ResearchCenter.vue'),
    meta: { title: "质量管理" }
  },
  { path: "/Talent",
    name: "Talent",
    component: () => import('../views/Talent/TalentCenter.vue'),
    children: [
      {
        path: '',
        name: 'JoinUs',
        component: () => import('../components/Talent/JoinUs.vue')
      },
      {
        path: 'system',
        name: "TalentSystem",
        component: () => import('../components/Talent/System.vue')
      },
      {
        path: 'JoinDetail',
        name: "JoinDetail",
        component: () => import('../components/Talent/JoinDetail.vue')
      }
    ]
  },
];

const router = new VueRouter({
  mode: "history", // 使用 history 模式，避免 URL 中出现 #
  routes
});

// 添加全局路由守卫
router.beforeEach((to, from, next) => {
  // 滚动到顶部
  window.scrollTo(0, 0);
  next();
});

export default router;
