<script >
export default {
  name: "bottom" ,
  computed: {
    breadcrumbs() {
      const matchedRoutes = this.$route.matched;
      return matchedRoutes.map(route => ({
        path: route.path,
        meta: route.meta
      }));
    },

  },
  data() {
    return {
      isDesktopView: true,
      scale: 1,
      showSearch: false,
      searchQuery: "",
      showMobileMenu: false,
      corporateSupplier:'新型饲用蛋白供应商',
      countWechat:'关注海邻官方微信',
      pathImgHaiLin:require('@/assets/hlgz.png'),
      weChatPath:require('@/assets/hailin01.png'),
      menuItems: [
        { title: '首页', path: '/' },
        { title: '关于海邻', path: '/About', subItems: [
            { title: '企业简介', path: '/About' },
            { title: '发展历程', path: '/History' },
            { title: '企业荣誉', path: '/Corporatehonors' },
            { title: '企业文化', path: '/Corporateculture' },
          ]},
        { title: '产品中心', path: '/Product', subItems: [
            { title: '产朊假丝酵母蛋白', path: '/Product/ProductCandidaYeast/pig' },
            { title: '酿酒酵母培养物', path: '/Product/ProductGrainWineYeast/pig' },
            { title: '含可溶物的玉米干酒精糟', path: '/Product/ProductCornDryAlcohol/pig' },
            { title: '其他', path: '/Product/other' },
          ]},
        { title: '技术中心', path: '/Research', subItems: [
            // { title: '科研中心', path: '/Research/' },
            { title: '科研团队', path: '/Research/team' },
            { title: '科研力量', path: '/Research/power' },
            { title: '研究内容', path: '/Research/content' },
            { title: '研究成果', path: '/Research/achievements' },
            { title: '质量管理', path: '/Research/quality' }
          ]},
        { title: '海邻动态', path: '/Dynamic/company', subItems: [
            { title: '公司动态', path: '/Dynamic/company' },
            { title: '媒体报道', path: '/Dynamic/media' },
            { title: '视频中心', path: '/Dynamic/video' }
          ]},
        { title: '人才中心', path: '/Talent', subItems: [
            { title: '加入我们', path: '/Talent' },
            { title: '人才体系', path: '/Talent/system' }
          ]},
        { title: '联系我们', path: '/Contact' }
      ],
      LinkAbout:[
        {
          Id:1,
          name:'网站地图',
        },
        {
          Id: 2,
          name: '友情链接',
        },
        {
          Id:3,
          name:'网站声明',
        },
      ],
      Copyright:' Copyright  © 2020 内蒙古海邻科技发展有限公司 ',
      ICP:' 蒙ICP备20002680号-1 ',
      About:' 蒙公网安备 1505020200720号 ',
      showQRCode: false,
      weixinIcon: require('@/assets/bottom/Weixin.png'),
      qrCodeImage: require('@/assets/Contact/海邻科技二维码.png'),
      qrPosition: { top: 0, left: 0 }
    };
  },

  methods: {
    checkScreenSize() {
      this.isDesktopView = window.innerWidth >= 1024;//768768
    },
    handleClick(event) {
      const rect = event.target.closest('.footer-wechat').getBoundingClientRect();
      this.qrPosition = {
        top: `${rect.bottom}px`,
        left: `${rect.left + 20}px`
      };
      this.showQRCode = true;
    }
  },

  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);

    this.updateScale();
    window.addEventListener('resize', this.updateScale);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScale);
  },
  toggleSearch() {
    this.showSearch = !this.showSearch;
  },
  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  },



};
</script>

<template>
  <div class="bottom-container">
    <footer class="footer">
      <div class="footer-container">
        <div class="footer-left">
          <img src="@/assets/bottom/HainLin-bottom.png" alt="海邻 LOGO" class="footer-logo">

          <div class="footer-slogan-container-line"></div>

<!--        <p class="footer-slogan">{{corporateSupplier}}</p>-->
          <div class="footer-wechat" @click="handleClick">

            <div class="wechat-left">
              <img
                :src="weixinIcon"
                alt="微信图标"
                class="wechat-icon"
              >
              <div class="bottom-About-weChart">
                <p>关注海邻官方微信</p>
              </div>
            </div>
          </div>

        </div>


<!--      <div class="footer-links">-->
<!--        <div class="footer-column" v-for="(item,index) in menuItems" :key="item.title" v-if="item.subItems">-->
<!--          <h3>{{item.title}}</h3>-->
<!--          <ul>-->
<!--&lt;!&ndash;            <li><router-link :to="item.path">· {{item.title}}</router-link></li>&ndash;&gt;-->
<!--            <div class="submenu">-->
<!--              <li>-->
<!--              <router-link class="subItem" v-for="subItem in item.subItems" :key="subItem.title" :to="subItem.path" >·  {{ subItem.title }}</router-link>-->
<!--              </li>-->
<!--            </div>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->

        <div class="footer-links" v-if="isDesktopView">
          <div class="footer-column" v-for="(item,index) in menuItems" :key="item.title" v-if="item.subItems">
            <h3>
              <router-link :to="item.path" class="footer-title">{{item.title}}</router-link>
            </h3><br>
            <ul>
              <div class="submenu">
                <li v-for="subItem in item.subItems" :key="subItem.title">
                  <router-link class="subItem" :to="subItem.path">{{ subItem.title }}</router-link>
                </li>
              </div>
            </ul>
          </div>
          <div class="footer-column">
            <h3>
              <router-link to="/contact" class="footer-title">联系我们</router-link>
            </h3>
          </div>
        </div>


      </div>


     <div class="sjBottom">
        <div class="footer-bottom">

          <div class="footer-bottom-links" v-for="(item,index) in LinkAbout" :key="item.Id">
            <span class="bottom-link">{{item.name}}</span>
          </div>




        <p class="footer-copyright" >
          <a href="https://beian.miit.gov.cn/" target="_blank">  {{Copyright}}</a>
          <a href="https://beian.miit.gov.cn/" target="_blank"> {{ICP}}</a>
          <a href="http://www.beian.gov.cn/" target="_blank"> {{About}} </a>
      </p>
      </div>
     </div>





    </footer>

    <!-- 二维码弹窗 -->
    <div
      class="qr-modal"
      v-if="showQRCode"
      @click="showQRCode = false"
      :style="{ top: qrPosition.top, left: qrPosition.left }"
    >
      <div class="qr-content" @click.stop>
        <img
          :src="qrCodeImage"
          alt="海邻科技二维码"
          class="qr-image"
        >
        <div class="close-btn" @click="showQRCode = false">×</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bottom-container {
  position: relative;
}

.footer {
  background: #2D374B;
  color: #fff;
  font-size: 14px;
  width: 100%;
  padding-top: 3%;
}
.footer-container {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 0 auto;
  box-sizing: border-box;

}

.footer-left {
  width: 20%;
  padding: 0;
  text-align: left;
  flex-shrink: 0;
  box-sizing: border-box;
  margin-left: 5%;
}

.footer-logo {
  width: 100%;
  margin: auto;
}

.footer-slogan-container-line {
  width: 100%;  /* 与 footer-logo 相同宽度 */
  height: 2px;
  background-color: #ffffff;
  margin: 15px 0;  /* 调整与logo的间距 */
  opacity: 0.6;  /* 使线条略微透明 */
}

.footer-slogan {
  font-size: 1.2vw;
  margin: 1% 0;
}

.footer-wechat {
  display: flex;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
  position: relative; /* 添加相对定位 */
}

.wechat-left {
  display: flex;
  align-items: center;
  gap: 10px; /* 图标和文字之间的间距 */
  padding-left: 20px; /* 左侧内边距 */
}

.wechat-icon {
  width: 32px; /* 调整图标大小 */
  height: 32px;
  filter: brightness(0.9); /* 略微调整图标亮度 */
}

.bottom-About-weChart p {
  color: #ffffff;
  margin: 0;
  font-size: 13px;
}

.footer-links {
  width:70%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding-right: 0;
  padding-bottom: 3%;

}

.footer-column {
  flex: 1;
  text-align: left;
  margin-bottom: 1.5%;
  margin-right: 3%;
  white-space: nowrap;
}

.footer-column h3 {

  font-size: 16px;
  font-family: "Source Han Sans CN";
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.476;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.footer-column ul li {
  margin-bottom: 5px;
}

.footer-column ul li a,
.footer-column ul li router-link {
  color: #fff !important;
  text-decoration: none;
  opacity: 1;
  transition: color 0.3s;
  font-size: 16px;
  font-family: "Source Han Sans CN";
  line-height: 1.833;
  text-align: left;
}

.footer-column ul li a:hover,
.footer-column ul li router-link:hover {
  color: #0099ff !important;
  text-decoration: underline;
}

.sjBottom {
  display: flex;
  flex-direction: column;
  align-items: center;  /* 居中对齐 */
  width: 100%;
  background-color:  #2D374B;  /* 绿色背景 */

}

.footer-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  flex-wrap: wrap;
  width: 100%;

}

.footer-bottom-links {
  margin-bottom: 0;
  margin-left: 5vw;

}

.footer-bottom-links .bottom-link {
  color: #fff;
  margin: 0 10px;
  cursor: default;
  font-size: 13px;
}

.footer-bottom-links .bottom-link:hover {
  text-decoration: none;
  color: #fff;
}

.subItem{
  display: flex;
  flex-direction: column;
}

.footer-copyright {
  display: flex;
  flex-direction: row;
  margin-right: 6%;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);

}
.footer-copyright a {
  color: white;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.4);
  white-space: nowrap;
  margin: 0 1%;
}



.footer-copyright a:first-child {
  margin-left: 10px;
}

/* 响应式设计768768 */
@media screen and (max-width: 1025px) {
  .footer {
    padding: 4% 0;
  }

  .footer-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    height: auto;
  }

  .footer-left {
    width: 80%;
    max-width: 300px;
    margin: 0 auto 30px;
    text-align: center;
  }

  .footer-logo {
    width: 100%;
    margin: 0 auto;
  }

  .footer-slogan-container-line {
    width: 100%;
    margin: 15px auto;
  }

  .footer-wechat {
    justify-content: center;
    padding: 10px 0;
  }

  .wechat-left {
    justify-content: center;
    padding-left: 0;
  }

  .footer-links {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 0 20px 30px;
  }

  .footer-column {
    flex: 0 0 calc(33.33% - 20px);
    margin: 0;
    min-width: 150px;
    text-align: center;
  }

  .footer-column h3 {
    font-size: calc(0.9vw + 12px);
    margin-bottom: 15px;
  }

  .footer-column ul li a,
  .footer-column ul li router-link {
    font-size: calc(0.8vw + 10px);
    text-align: center;
  }

  .footer-bottom {
    flex-direction: column;
    text-align: center;
    padding: 15px;
    margin: 0;
  }

  .footer-bottom-links {
    margin-bottom: 15px;
  }

  .footer-copyright {
    flex-direction: column;
    align-items: center;
    margin: 0;
    gap: 8px;
  }

  .footer-copyright a {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .footer-column {
    flex: 0 0 calc(50% - 20px);
  }

  .footer-column h3 {
    font-size: calc(1.1vw + 12px);
  }

  .footer-column ul li a,
  .footer-column ul li router-link {
    font-size: calc(1vw + 10px);
  }

  .footer-bottom-links .bottom-link {
    font-size: 11px;
  }

  .footer-copyright a {
    font-size: 11px;
  }
}

@media screen and (max-width: 480px) {
  .footer-column {
    flex: 0 0 100%;
  }

  .footer-column h3 {
    font-size: 16px;
  }

  .footer-column ul li a,
  .footer-column ul li router-link {
    font-size: 14px;
  }

  .footer-left {
    width: 90%;
  }

  .wechat-icon {
    width: 28px;
    height: 28px;
  }

  .bottom-About-weChart p {
    font-size: 12px;
  }
}

@media screen and (min-width: 1026px) and (max-width: 1440px) {
  .footer-container {
    width: 90%;
  }

  .footer-left {
    width: 25%;
    margin-left: 2%;
  }

  .footer-links {
    width: 65%;
    padding-right: 2%;
  }

  .footer-column h3 {
    font-size: 15px;
  }

  .footer-column ul li a,
  .footer-column ul li router-link {
    font-size: 14px;
  }
}

/* 优化二维码弹窗样式 */
.qr-modal {
  position: fixed;
  left: 20px;
  margin-top: 10px;
  /* background-color: #2b3443; */
  border-radius: 8px;
  z-index: 1000;
  width: 100px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.qr-content {
  background-color: #2b3443;
  position: relative;
  padding: 10px; /* 减小内边距 */
  border-radius: 8px;
}

/* 调整三角形指示器位置 */
.qr-modal::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 20px; /* 调整水平位置 */
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #2b3443;
  z-index: 1001; /* 确保三角形在最上层 */
}

.qr-image {
  max-width: 200px;
  height: 80px;
  display: block;
  margin: 0 auto; /* 居中显示 */
}

/* 确保二维码容器有足够的空间 */
.qr-content {
  width: 100px;
  height: 100px;
  display: flex;
  margin-top: -30px;
  align-items: center;
  justify-content: center;
}

.close-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 24px;
  height: 24px;
  background-color: #2b3443;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1002; /* 确保关闭按钮在最上层 */
}

/* 添加动画 */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* 移动端适配768768 */
@media screen and (max-width: 1025px) {
  .qr-image {
    max-width: 160px;
  }

  .close-btn {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }

  .bottom-About-weChart p {
    font-size: 12px;
  }
}

.footer-title {
  text-decoration: none;
  color: inherit;
}

.subItem {
  text-decoration: none;
  color: inherit;
}
</style>
