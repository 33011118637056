import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './font/font.css'
import VueLazyload from 'vue-lazyload'

window.addEventListener("resize", () => {
  store.dispatch("checkDevice");
});

Vue.config.productionTip = false

// 配置 VueLazyload
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('@/assets/error.png'), // 加载失败时显示的图片
  loading: require('@/assets/loading.gif'), // 加载中显示的图片
  attempt: 1
})

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'vAb5kTsTa2vjIdSLrQb5JwT9dWII6Kaj'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
