import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    deviceType: "pc", // 默认值
  },
  mutations: {
    updateDeviceType(state) {
      const width = window.innerWidth;
      if (width >= 1024) {
        state.deviceType = "pc";
      } else if (width >= 768) {
        state.deviceType = "tablet";
      } else {
        state.deviceType = "mobile";
      }
    },
  },
  actions: {
    checkDevice({ commit }) {
      commit("updateDeviceType");
    },
  },
  getters: {
    isPc: (state) => state.deviceType === "pc",
    isTablet: (state) => state.deviceType === "tablet",
    isMobile: (state) => state.deviceType === "mobile",
  },
});
